import React from "react"
import notFound from "../../assets/404.jpg"
import { NotFoundNumber } from "../../assets/icons"
import { Link } from "gatsby"
import "./index.scss"

const NotFoundPageContent = () => {
  return (
    <div className="not-found-page-content-holder">
      <img src={notFound} alt="Not Found" className="not-found-img" />
      <div className="not-found-content">
        <div className="not-found-number">
          <NotFoundNumber className="number" />
        </div>
        <h1 className="not-found-desc">
          Hmmm, izgubili ste se… Ali barem imate osiguranje?
        </h1>
        <Link to="/">
          <button className="look-for-insurance-btn">Pronadi osiguranje</button>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPageContent
