import React, { useEffect } from "react"
import Helmet from "react-helmet"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
import NotFoundPageContent from "../views/NotFoundPageContent"

const NotFoundPage = () => {
  // useEffect(() => {
  //   document.title = "Dalmatiko osiguranje | 404"
  // }, [])

  // <Layout>
  //   <SEO title="404: Not found" />
  //   <h1>404: Not Found</h1>
  //   <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  // </Layout>
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dalmatiko osiguranje | 404</title>
        <link rel="canonical" href="https://dalmatiko-osiguranje.hr/404" />
      </Helmet>
      <NotFoundPageContent />
    </>
  )
}

export default NotFoundPage
